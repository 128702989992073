<template lang='pug'>
  v-container#user-profile( style='max-width: 1280px' tag="section")
    // Dialog update profile
    v-dialog( v-if="updateItemDialog" persistent :value='updateItemDialog'  max-width='800px'   transition="dialog-bottom-transition")
        div(style ='position:relative; height: 100%;')
            update-item(:model ='profile' @close="updateItemDialog=false")
            div(style='position:absolute; top:0; width:100%;')
                v-btn.mt-2.ml-2(absolute right  icon color='danger' style="top: -45px; z-index:999;" medium,  dark='', @click.native='updateItemDialog=false')
                    v-icon(size="30") mdi-close-box
    // WithDraw
    v-dialog( v-if="withdrawDialog"  :value='withdrawDialog'  max-width='500px'   transition="dialog-bottom-transition")
        div(style ='position:relative; height: 100%;')
            withdraw-dialog(v-if="withdrawDialog" @close="withdrawDialog=false" @addBanking="addBanking")
            div(style='position:absolute; top:0; width:100%;')
                v-btn.mt-2.ml-2(absolute right  icon color='danger' style="top: -45px; z-index:999;" medium,  dark='', @click.native='withDrawDialog=false')
                    v-icon(size="30") mdi-close-box
    // Banking info
    v-dialog(:value='bankingDialog' max-width='800px' transition="dialog-bottom-transition" )
      BankingForm(v-if='bankingDialog' :banking="banking" @close="bankingDialog = false")
    //- Avatar
    v-row
      v-col
        Avatar(v-if="isMobile" :profile="profile" @openUpdate="updateItemDialog=true")
    //- balance
    v-row
      v-col
        Balance(v-if="isMobile" @withdraw="withdrawDialog = true")
    //- information
    v-row(justify="center")
        v-col(cols="12" md="12")
          //- Alert banking info
          v-alert(v-if="!banking || !banking.account" v-model="alert" close-text="Close Alert" color="secondary" dark dismissible)
            | Please complete your profile in order to withdraw, fill out your bank information.
            br
            | Scene finder won't share your personal information.
          //- Menu
          v-card.custom-card-information.mt-0(v-if="isMobile")
            v-tabs(:vertical="verticalTab" left class="pt-5 pb-5 pr-4 pl-4")
              // Tabs
              v-tab
                v-icon(left="") mdi-account
                | Profile
              v-tab
                v-icon(left="") mdi-book
                | History
              v-tab
                v-icon(left="") mdi-cash
                | Withdraw
              // Profile tab
              v-tab-item
                v-form
                  v-container.py-0
                      v-row(no-gutters)
                          v-col(cols="4")
                            v-subheader.pa-0.mt-3 First Name
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" v-model="profile.first_name")
                          v-col(cols="4")
                            v-subheader.pa-0.mt-3 Last Name
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" v-model="profile.last_name")
                          v-col(cols="4")
                            v-subheader.pa-0.mt-3 Email
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" v-model="profile.email")
                          v-col(cols="4")
                            v-subheader.pa-0.mt-3 Phone
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" v-model="profile.phone")
              // History tab
              v-tab-item
                v-card
                  v-container.py-0
                    v-form
                      v-row
                        v-col(cols="12" md="12")
                            v-text-field(label="Transaction type" disabled="" value="Upload")
                        v-col(cols="12" md="12")
                            v-text-field.purple-input(label="Number of downloads" disabled="" :value="profile.downloads_contributor")
                        v-col(cols="12" md="12")
                            v-text-field.purple-input(label="Total earned" disabled="" :value="profile.total_earning+'$'")
              // Withdraw
              v-tab-item
                v-form
                  v-container.py-0
                    template(v-if="banking.account")
                      v-row
                          v-col(cols="12" md="12")
                            h3 Banking information
                          v-col(cols="5")
                            v-subheader.pa-0.mt-3 First Name
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" v-model="banking.first_name")
                          v-col(cols="5")
                            v-subheader.pa-0.mt-3 Last Name
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" v-model="banking.last_name")
                          v-col(cols="5")
                            v-subheader.pa-0.mt-3 Email
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" v-model="banking.email")
                          v-col(cols="5")
                            v-subheader.pa-0.mt-3 Account
                          v-col(cols="8")
                            v-text-field.purple-input(disabled="" v-model="banking.account")
                    template(v-if="withdraws")
                      v-data-table(:headers="headersWithdraw" :items="withdraws" dense :items-per-page="5" :search="search")
                        template( v-slot:item="props" )
                          tr
                            td {{ props.item.created | moment}}
                            td {{ props.item.type }}
                            td {{ props.item.status }}
                            td {{ props.item.amount | moneyFormat}}
                    template(v-else)
                      v-row
                        v-col(cols="12")
                          v-card-title.justify-center.font-weight-light
                            | You haven't withdraws yet
                          v-card-text.body-1.text-center.font-weight-light.grey--text
                            | The withdraws appear here.
          v-card.custom-card-information.mt-0.user-profile__tab(v-else)
            v-tabs(:vertical="verticalTab" left color="#3e7f9d" class="pt-5 pb-5 pr-4 pl-4")
              // Tabs
              v-tab
                v-icon(left="") mdi-account
                | User Profile
              v-tab
                v-icon(left="") mdi-bank
                | Bank Information
              v-tab
                v-icon(left="") mdi-currency-usd
                | Withdraw history
              // Profile tab
              v-tab-item
                v-form
                  v-container.py-0
                      v-row
                        v-col(cols="8")
                          v-card.pa-1
                            v-row
                              v-col(cols="4")
                                v-text-field(label="Name" disabled="" v-model="profile.first_name")
                              v-col(cols="4")
                                v-text-field(label="Last Name" disabled="" v-model="profile.last_name")
                              v-col(cols="4")
                                v-text-field(label="Email" disabled="" v-model="profile.email")
                              v-col(cols="6")
                                v-text-field.purple-input(label="City" disabled="" v-model="profile.city")
                              v-col(cols="6")
                                v-text-field.purple-input(label="State" disabled="" v-model="profile.state")
                              v-col(cols="6")
                                v-text-field.purple-input(label="phone" disabled="" v-model="profile.phone")
                              v-col(cols="12").text-center
                                v-btn(color="secondary" rounded="" small @click.stop='updateItemDialog=true')
                                  | Edit profile
                        v-col(cols="4").mt-6
                          base-material-card(:avatar="profile.image ? profile.image:userImage")
                            v-card-text.text-center
                              p.grey--text
                                | {{profile.email}}
                              h3.mt-2.grey--text
                                | {{profile.first_name}}
                              p.mt-2.grey--text
                                | {{profile.phone}}
              // Bank Information
              v-tab-item
                v-container
                  v-row
                    v-col(cols="4")
                      v-card(flat class="custom-card-transactions")
                        .custom-card__title
                          h2.custom-card-transactions__balance
                            | {{earnings | moneyFormat}}
                      v-card-text.text-center
                        v-btn.mr-0(color="secondary" small rounded @click='withdrawDialog = true')
                          | Request A Withdraw
                    v-col(cols="8")
                      v-row(v-if="banking.account")
                        v-col(cols="12")
                          h3.text-center
                            | Banking
                          v-row
                            v-col(cols="6")
                              v-text-field.purple-input(label="Account" disabled="" v-model="banking.account")
                            v-col(cols="6")
                              v-text-field.purple-input(label="Email" disabled="" v-model="banking.email")
                            v-col(cols="6")
                              v-text-field.purple-input(label="Name" disabled="" v-model="banking.first_name")
                            v-col(cols="6")
                              v-text-field.purple-input(label="Last name" disabled="" v-model="banking.last_name")
                      template(v-else)
                        v-row
                          v-col(cols="12")
                            v-card-title.justify-center.font-weight-light
                              | No Banking information.
                          v-col(cols="12").text-center
                            v-btn.mr-0(large color="secondary" @click='bankingDialog = true')
                              | set Banking information
              // Withdraws
              v-tab-item
                v-form
                  v-container.py-0
                    template(v-if="withdraws")
                      v-data-table(:headers="headersWithdraw" :items="withdraws" dense :items-per-page="5" :search="search")
                        template( v-slot:item="props" )
                          tr
                            td {{ props.item.created | moment}}
                            td {{ props.item.type }}
                            td {{ props.item.status }}
                            td {{ props.item.amount | moneyFormat}}
                            td {{ props.item.amount_charity | moneyFormat}}
                            td {{ props.item.charity}}
                    template(v-else)
                      v-row
                        v-col(cols="12")
                          v-card-title.justify-center.font-weight-light
                            | You haven't withdraws yet
                          v-card-text.body-1.text-center.font-weight-light.grey--text
                            | The withdraws appear here.
</template>

<script>
  import userImage from '@/assets/user.png'
  import loaders from '@/plugins/mixins/loaders'
  import WithdrawDialog from '@/components/custom/dialogs/WithDrawDialog'
  import moment from 'moment'
  const formatter = new Intl.NumberFormat('es-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })
  export default {

    components: {
      WithdrawDialog: WithdrawDialog,
      Avatar: () => import('@/components/custom/Avatar'),
      Balance: () => import('@/components/custom/Balance'),
      UpdateItem: () => import('../custom/Update/UpdateProfile'),
      BankingForm: () => import('@/components/custom/forms/BankingForm.vue'),
    },

    filters: {
      moment: function (date) {
        return moment(date).format('MM-DD-YYYY')
      },
      moneyFormat: function (value) {
        return formatter.format(value)
      },
    },

    mixins: [loaders],

    data () {
      return {
        verticalTab: this.prueba(),
        userImage: userImage,
        model: {},
        updateItemDialog: false,
        withdrawDialog: false,
        bankingDialog: false,
        search: '',
        headers: [
          { text: 'Brand', value: 'brand' },
          { text: 'Numbers', value: 'number' },
          { text: 'Amount', value: 'amount' },
          { text: 'Plan', value: 'plan' },
        ],
        headersWithdraw: [
          { text: 'Date', value: 'created' },
          { text: 'Type', value: 'type' },
          { text: 'Status', value: 'status' },
          { text: 'Your account', value: 'amount' },
          { text: 'Your charity', value: 'amount_charity' },
          { text: 'Charity name', value: 'charity' },
        ],
      }
    },
    computed: {
      profile () {
        return this.$store.state.users.profile
      },
      earnings () {
        return this.$store.getters['withdraw/getEarnings']
      },
      isLoading () {
        return this.$store.getters['users/getLoading']
      },
      isMobile () {
        return this.$store.getters['users/getIsMobile']
      },
      withdraws () {
        return this.$store.getters['withdraw/getWithdraw']
      },
      banking () {
        return this.$store.getters['withdraw/getBanking']
      },
    },

    created () {
      const vm = this
      vm.loadWithdraw()
      vm.loadBanking()
    },

    methods: {
      addBanking () {
        this.bankingDialog = true
        this.withdrawDialog = false
      },
      prueba () {
        const verticalTab = (this.windowWidth <= '425px')
        return verticalTab
      },
    },
  }
</script>

<style lang="scss" scoped>
.custom-card-profile{
  margin-top: 50px;
  border-radius: 17px !important;
  position: relative;
  .btn-rol{
    font-size: 12pt;
    padding-top: .5rem;
    padding-bottom: .5rem;
    height: 50px;
  }
  &__settings{
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 25px;
    height: auto;
  }
  &__avatar{
    width: 150px;
    height: 150px;
    background-color: #eee;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto -50px;
    transform: translateY(-50%);
    .v-avatar{
      width: 90% !important;
      height: 90% !important;
    }
    img{
    border: 5px solid var(--font-color-2);
    // width: 60;
    //   width: 80%;
    //   height: auto;
    //   border-radius: 50%;
    }
  }
  &__username,
  &__usertype {
    color: var(--font-color-2);
  }
  &__usertype{
    font-weight: ligth;
  }
}
.custom-card-transactions{
  background-color: var(--font-color-2);
  border-radius: 17px !important;
  &__title,
  &__balance{
    color: white;
  }
  &__title{
    font-size: 18pt;
  }
  &__balance{
    font-size: 25pt;
    font-weight: bold;
  }
  &__buttons{
  }
}
.custom-card-information {
  border-radius: 17px !important;
  border-bottom-left-radius: 17px !important;
  border-bottom-right-radius: 17px !important;
}
</style>
