<template lang="pug">
v-container(fluid)
  // dialog
  v-dialog(persistent hide-overlay  v-model='confirmationDialog' width="300px"  transition="dialog-bottom-transition")
    v-card(style="border: 1px solid #777;")
        v-card-title.headline Confirm your withdraw
        v-card-text.text-center
            | Are you sure to make the withdraw?
        v-card-actions.text-center
            v-spacer
                v-btn(color="primary" @click="confirmationDialog = false") Cancel
                v-btn(color="secondary" @click="solicitedTransfer") Yes, proceed
  // contente
  v-card
    v-card-title
      | Withdraw
      v-spacer
        v-icon(aria-label="Close" @click="$emit('close')")
          | mdi-close
    v-card-text.body-1.text-center
        v-row(v-if="pending")
            v-col(cols="12" md="12").mb-0
                h3.mb-0.pb-0 {{earnings | moneyFormat}},
                v-card-title.justify-center.font-weight-light
                | You have a pending withdraw.
                v-card-text.body-1.text-center.font-weight-light.grey--text
                | When the administrator approves it you can withdraw your funds.
            v-col(cols="12" md="12").text-center
                v-btn.mr-0(color="success" rounded="" @click="$emit('close')")
                    | Close
        v-row(v-else)
            v-col(cols="12" md="12")
                h3 The minimum you can withdraw is $ 20.00 USD.
            v-col(cols="12" md="12")
                h3 $ {{earnings}} USD.
            v-col(v-if="earnings >= 20" cols="12" md="12")
                //- type of withdraw
                label Select type of withdraw
                v-select(v-model="type" :items="types" menu-props="auto" label="Select type of withdraw" single-line)
                //- amount of withdraw
                span(v-if="type")
                    label How much you want to withdraw?
                    v-text-field(v-model="amount" type="number" min="20" :max="earnings")
                    p(v-if="amount > earnings").primary--text The amount cannot be greater than the earnings
                //- asmounts
                span(v-if="amount > 0 && amount <= earnings")
                    label Would you like to donate something to charity?
                    v-slider(label="Withdraw" v-model="amountWithdraw" :max="amount" :min="0" thumb-label="always" @input="updateAmount('withdraw')").mt-2
                    v-slider(label="Charity" v-model="amountCharity" :max="amount" :min="0" thumb-label="always" @input="updateAmount('charity')").mt-2
                    //- -charitys
                    v-autocomplete(v-if="amountCharity > 0" v-model="charity" :items="charitys" item-text="title" item-value="description" menu-props="auto" label="Select type of charity" single-line)
                    v-card-text.body-1.font-weight-light.pa-1.text-justify {{charity}}
                span(v-if="type === 'My bank account' && !banking.account")
                    v-card-text.body-1.font-weight-light.pa-1.text-justify
                        | No Banking information. To transfer the funds you must add your bank account.
                        v-btn(color="primary" text small @click="$emit('addBanking')")
                            | add banking info
            v-col(cols="12" md="12").text-center
                v-btn.mr-0(color="success" rounded="" :loading="loading" :disabled="!type || earnings < 20 || (type === 'My bank account' && !banking.account) || amount > earnings || amount < 20 || amount <= 0 || (amountCharity > 0 && !charity)" @click="confirmationDialog = true")
                    | Withdraw
</template>

<script>
  /* eslint-disable */
    const formatter = new Intl.NumberFormat('es-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    })
    export default {

        filters: {
            moneyFormat: function (value) {
                return formatter.format(value)
            },
        },

        data () {
            return {
                loading: false,
                pending: '',
                type: '',
                types: ['My bank account', 'Visa card', 'Check'],
                charity: '',
                charitys: [
                    {title: 'Africa Disease and Poverty Eradication Project', description: 'They helps refugees providing food, medical help and shelter in Saharan Africa.'},
                    {title: 'Air Compassion for Veterans', description: 'Provide free air and ground transportation services for ill/injured/wounded veterans or an adversely affected family.'},
                    {title: 'Alliance for Cancer Gene Therapy Inc', description: 'We fund research for safe, effective cell and gene therapies that eliminate devastating side effects.'},
                    {title: 'Alzheimers and Aging Research Center', description: 'Supports research dedicated to understanding Alzheimers and to finding effective Alzheimers'},
                    {title: 'Alzheimers Association', description: 'Is the leading voluntary health organization in Alzheimers and dementia care and support.'},
                    {title: 'American Association of Sociology', description: 'Suicide is the 2nd leading cause of death among young people. Through public education/information we are working to prevent this tragedy.'},
                    {title: 'American Bible Study', description: 'Making the Bible available to every person in a language and format each can understand and afford, so they may experience its life changing message.'},
                    {title: 'American Cancer Society', description: 'We do much more than breakthrough research. We offer free rides to treatment, a 24/7 helpline, and free lodging.'},
                    {title: 'American National Red Cross', description: 'A humanitarian organization, that prevents and alleviates human suffering in the face of emergencies large and small.'},
                    {title: 'Arthritis & Chronic Pain Research', description: 'Your contribution helps provide research grants to scientists working on understanding chronic pain and finding more effective medications to treat pain.'},
                    {title: 'Association of Gospel Rescue Missions', description: 'Providing vision, education, training, resources, guidance, and representation for rescue missions and kindred ministries.'},
                    {title: 'Autism Research- The Autism Society of America Foundation', description: 'We support research that offers hope for dignified & independent lives & programs that focus on positive and measurable outcomes.'},
                    {title: 'Baptist World Alliance', description: 'Cares for the hungry and homeless of the world by providing food, medicine, encouragement, hope.'},
                    {title: 'Be The Match Foundation', description: 'We help patients who require a blood or marrow transplant receive the treatment they need.'},
                    {title: 'Black Womens Health Imperative', description: 'We are the only national organization dedicated to improving the health and wellness of Black women and girls--physically, emotionally and financially.'},
                    {title: 'Blessings International', description: 'Saves lives globally and in the USA by providing medicine, vitamins, and medical supplies to treat the poor and victims of disease.'},                    
                    {title: 'Boys & Girls Club of America', description: 'To enable all young people, especially those who need us most, to reach their full potential as productive, caring, responsible citizens.'},
                    {title: 'Cadence International', description: 'A Christian ministry transforming U.S. military communities through evangelism, Bible studies, counseling, retreats and hospitality.'},
                    {title: 'Center for Family and Child Enrichment', description: 'Helping children & families help themselves live a better life and build a stronger community.'},
                    {title: 'Charity Water', description: 'Brings clean, safe drinking water to people in Africa and the developing world. Partners with local groups to build and maintain wells.'},
                    {title: 'Child Health Foundation', description: 'Help prevent unnecessary illness and death of children through immunizations, water purification and proper nutrition.'},
                    {title: 'Christian Service Charities', description: 'Works to bring life sustaining support to faith-based nonprofit partners serving vulnerable people in local communities and across the globe.'},
                    {title: 'City of Hope', description: 'A world leader in cancer research, treatment & prevention. Doctors partner with scientists to transform laboratory breakthroughs into treatments.'},
                    {title: 'Community Health Charities', description: 'Building stronger communities and empowering people to take action to improve health and wellbeing.'},
                    {title: 'Core Foundation Inc', description: 'Provide needed financial support and education for individuals with spinal cord injuries and other neurological conditions.'},
                    {title: 'Crisis Services (Suicide Prevention and Crisis Service INC)', description: 'We are a 24-hour comprehensive crisis center and community resource whose first responders provide support to anyone in need.'},
                    {title: 'Diabetes National Group', description: 'Your support helps our scientists continue their innovative research to understand and find treatments for both Type 1 and Type 2 diabetes.'},
                    {title: 'Diabetes Research Institute Foundation', description: 'Provide the Diabetes Research Institute with the funding necessary to cure diabetes now.'},
                    {title: 'Doctors Without Borders', description: 'An independent international medical humanitarian organization that delivers emergency aid to people affected by armed conflict, epidemics and natural disasters.'},
                    {title: 'Engineering Ministries International', description: 'Designing a World of Hope, mobilizing engineers, architects, surveyors, and construction managers to design and build Christian hospitals, schools, etc.'},
                    {title: 'Family Research Council Inc', description: 'Advancing faith, family, and freedom in government and culture from a Christian worldview.'},
                    {title: 'Feeding America', description: 'The nations leading domestic hunger-relief organization providing food and services to 46 million Americans annually.'},
                    {title:'Food For the Hungry', description: 'A Christian international relief and development organization responding to human suffering and graduating communities from extreme poverty.'},
                    {title:'Futures Without Violence', description: 'We work to heal those traumatized by violence and to create healthy families and communities free of violence.'},
                    {title:'Gift of Life Marrow Registry Inc', description: 'We believe every person battling blood cancer deserves a second chance at life and we are determined to make it happen.'},
                    {title:'Global Green', description: 'Our mission is to foster a global value shift toward a sustainable and secure future in order to help people, places, and the planet. '},
                    {title:'Goodwill', description: 'We work to enhance people’s dignity and quality of life by strengthening their communities and helping them reach their full potential.'},
                    {title:'Graves Disease and Thyroid Function', description: 'Early treatment can help avoid bone/muscle wasting, heart problems and thyroid storm.  We are giving help, hope and support.'},
                    {title:'Habitat for Humanity', description: 'Help builds or renovates housing in partnership with families to improve their health, education, financial stability and neighborhoods.'},
                    {title:'Health & Medical Research Charities of America', description: 'Help support wellness in America with one gift to charities providing research, screening, and diagnosis, treatment, prevention & patient comfort.'},
                    {title:'Health First American Charities', description: 'Supports charity efforts to care for the sick, alleviate human suffering, provide counsel, and conduct research to discover new medical treatments.'},
                    {title:'Hearing Loss Association of America Inc', description: 'We advocate for affordable hearing aids, accessible hearing healthcare, ADA compliance, and accessible assistive/emerging communication technologies.'},
                    {title:'Heart Disease Aid and Research Fund', description: 'Awards grants to hospitals that do research and treat heart disease patients. Ships medical supplies, equipment and humanitarian aid to hospitals.'},
                    {title:'HollyRod Foundation', description: 'Provides "medical, physical, and emotional support" to individuals living with Parkinsons disease as well as families of children with autism.'},
                    {title:'Human & Civil Rights Organizations of America', description: 'We support human rights and civil rights.  Defend democratic principles, and promote equality, freedom, and justice for all.'},
                    {title:'Humane Society of the United States', description: 'Animal welfare organization working to end abuse of all animals.'},
                    {title:'Kids for the Kingdom Inc', description: 'Equip and empower disadvantaged children worldwide to transform their nation for Christ through feeding programs, schools, emergency aid, Bible clubs, evangelism etc.'},
                    {title:'Lupus LA', description: 'The Lupus Foundation of America works to improve the quality of life for all people affected by lupus through programs of research, education, support and advocacy.'},
                    {title:'Make-A-Wish Foundation of America', description: 'We create life-changing wishes for children with critical illnesses. We grant a wish, on average, every 34 minutes'},
                    {title:'March of Dimes Inc', description: 'Prematurity is the #1 killer of babies in the United States. We fund research, focused on giving every child a fighting chance at a healthy start.'},
                    {title:'Masonic Foundation for Children', description: 'We assist schools in successfully identifying and combating serious issues in our at-risk youth. We believe the best treatment is prevention. '},
                    {title:'Melanoma Research Foundation', description: '100% of donations go directly to melanoma research thanks to the support of MRA founders who cover all admin & development expenses.'},
                    {title:'Metropolitan Ministries Inc', description: 'To care for the homeless and those at risk of becoming homeless in our community through services that alleviate suffering, as an ongoing ministry of Jesus Christ.'},
                    {title:'Mind Body & Soul Health Ministry', description: 'Helping the undeserved by providing health care services in the Caribbean.'},
                    {title:'Mr. Hollands Opus Foundation', description: 'Keeps music alive for thousands of students by donating musical instruments to under-served schools and kids, to inspire creativity.'},
                    {title:'Multiple Sclerosis Association of America', description: 'Distribution of durable medical equipment and cooling vests, MRI assistance, publications, videos, educational programs, and other vital services.'},
                    {title:'NAACP Foundation', description: 'Protecting and advancing civil rights by eliminating disparities in: education, criminal justice, economic opportunity, health care, housing, environmental justice, legal redress, etc.'},
                    {title:'National Down Syndrome', description: 'Focusing on public policy, public awareness, health promotion and resources, community outreach and support, and the #DSWORKS® employment program.'},
                    {title:'National Society of Black Engineers', description: 'We strive to fulfill our overall mission to strengthen the pipeline of students of color that become STEM professionals with free programming, workshops, and mentoring.'},
                    {title:'Oceana Inc.', description: 'Focused solely on ocean conservation. We work together to win strategic, directed campaigns that aim to protect marine wildlife and human health, and ensure healthy, abundant oceans.'},
                    {title:'Our Daily Bread Ministries', description: 'To make Our Daily Bread and other Bible-based materials available to as many people as possible at no charge.'},
                    {title:'Ovarian Cancer Research Alliance', description: 'Advance ovarian cancer research and will help us continue our vital work educating the community, pursing policies and developing programs.'},
                    {title:'Paralyzed Veterans of America', description: 'To improve the quality of life for Veterans of the US Armed Forces who have a spinal cord injury or disease.'},
                    {title:'Parkinson Network of Arizona', description: 'Offering a variety of caregiver-focused classes, the center always keeps the caregiver in mind and works to provide tools that can make life easier for them.'},
                    {title:'Parkinsons and Brain Research Foundation', description: 'Less Expenses. More Research. More Results. 99 cents of every dollar spent goes to research. Cutting edge research can cure Parkinsons and Alzheimers.'},
                    {title:'Partners Therapeutic Horsemanship Inc', description: 'We provide therapeutic horsemanship as a unique means to improve the quality of life for those with special needs, their families and the community.'},
                    {title:'Places of Hope Inc', description: 'We provide stable and loving family environments for families. We are committed to meeting desperate needs by sharing God’s love and placing hope in their lives.'},
                    {title:'PTSD Foundation of America', description: 'Many military veterans suffer from unseen wounds of war. Help us give back to those who have given of themselves so selflessly by offering healing and hope.'},
                    {title:'Reflex Sympathetic Dystrophy Syndrome Association', description: 'Providing support, education, and hope to everyone affected by CRPS/RSD, the most painful, debilitating condition. '},
                    {title:'Rettsyndrome.org (International Rett Syndrome Foundation)', description: 'Our research is key to addressing this devastating neurological disorder and improving the quality of life for those living with RS.'},
                    {title:'Samaritans Purse', description: 'Christian relief agency, led by Franklin Graham, providing food, medicine, clothing, shelter, and other emergency assistance to victims of war, poverty, disease, and natural disasters.'},
                    {title:'Save The Children', description: 'We do whatever it takes – every day and in times of crisis – transforming children’s lives and the future we share.'},
                    {title:'Scleroderma Research Foundation', description: 'SRF leads the battle against this life-threatening and debilitating disease. We fund scientific research and continue to make breakthrough discoveries that save lives'},
                    {title:'Second Chance for Strays', description: 'We believe in spay/neutering, NOT euthanasia. All our animals are altered to prevent more homeless animals. 100% of donations are to help the animals.'},
                    {title:'Share the Dream Foundation Inc.', description: 'To educate and empower at-risk youth and veterans by providing food, clothes, counseling, education, and do whatever is needed to get them off the streets.'},
                    {title:'Shriners Hospitals For Children', description: 'Changing lives every day through innovative pediatric specialty care, world-class research & medical education. We treat children suffering, regardless of ability to pay.'},
                    {title:'Sjogrens Syndrome Foundation Inc', description: 'Sjogrens is one of the most prevalent autoimmune diseases, with 9 out of 10 patient’s women. Help us find new treatments and ultimately a cure.'},
                    {title:'Somaly Mam Foundation', description: 'We are dedicated to the eradication of slavery and the empowerment of its survivors, based on the vision and lifes work of Cambodian survivor Somaly Mam. '},
                    {title:'Spinal Cord Injury Network', description: 'Newly disabled & their families are faced with frustrations, emotional trauma & social barriers. We are here to assist with the best possible care & resources.'},
                    {title:'Spondylitis Association of America', description: 'We support medical research initiatives, educational outreach programs, treatment advances, and the ongoing search for a cure for this crippling form of arthritis.'},
                    {title:'St. Judes Children Research Hospital', description: 'We lead the way the world understands, treats and defeats childhood cancer and other life-threatening diseases. Families never receive a bill from St. Jude. '},
                    {title:'Step Up for Students', description: 'Empowers parents to pursue and engage in the most appropriate learning options for their children through scholarships, advocacy and an array of support services.'},
                    {title:'Susan G. Komen Foundation', description: 'Imagine life without breast cancer. Make it a reality for all nations by racing for the cures and funding life-saving research and community health programs.'},
                    {title:'The Amazing Grace Conservatory', description: 'We train and develop emerging artists and at-risk youth in the performing arts by offering a well-rounded, culturally enriching educational programs.'},
                    {title:'The Enough Project', description: 'The Enough Project supports peace and an end to mass atrocities in Africa’s deadliest conflict zones.'},
                    {title:'The Marfan Association', description: 'We work tirelessly to advance lifesaving and life-changing research, serve as a resource for families and healthcare providers, and raise public awareness about Marfan syndrome.'},
                    {title:'The Navigators', description: 'Provides a spiritual presence on military bases alongside chaplains and brings hope to military personnel and their families through meaningful relationships with Jesus Christ.'},
                    {title:'The Salvation Army', description: 'We work alongside communities to improve the health, economic, educational and spiritual conditions of the worlds most vulnerable.'},
                    {title:'The Trayvon Martin Foundation Inc.', description: 'We are a nonprofit organization whose main purpose is to provide both emotional and financial support to families who have loss a child to gun violence.'},
                    {title:'Thyroid Association', description: 'We are dedicated to transforming thyroid care through clinical excellence, education, scientific discovery and advocacy in a collaborative community.'},
                    {title:'Toys for Tots', description: 'Brighten a childs face on Christmas morning. Help the Marines provide a treasured gift for millions of our nations less fortunate children.'},
                    {title:'Trans World Radio', description: 'Life changing help and hope for men, women, children, through broadcasting biblically based programming, and follow-up, in 230 languages to listeners in over 190 countries.'},
                    {title:'UNICEF USA', description: 'We are working toward the day when no children die from preventable causes, and every child has a safe and healthy childhood.'},
                    {title:'United Negro College Fund', description: 'We builds a robust and nationally recognized pipeline of under-represented students who, with UNCF support, become qualified college graduates.'},
                    {title:'Urology Care Foundation', description: 'Is a leader in urologic health, advancing research and education to improve treatments and find cures for urologic diseases.'},
                    {title:'Violence Policy Center', description: 'From mass shootings to domestic abuse, guns are tearing America apart. Help us stop this public health epidemic that claims more than 38,000 lives annually.'},
                    {title:'Voice of the Martyrs Inc.', description: 'Christian ministry that aids, equips and encourages persecuted believers annually in 68 nations, bringing Christ’s hope through shelter, medicines, Bibles and training.'},
                    {title:'Water Missions International', description: 'We are a Christian engineering organization that designs & implements safe water, sanitation and hygiene solutions for people in developing countries & disaster areas.'},
                    {title:'We Win Foundation', description: 'It was formed to help individuals, as well as the families and caregivers who support those suffering with the "Cruel Mystery" (Lupus) and other related autoimmune diseases.'},
                    {title:'Womanhaven', description: 'To promote & provide a response to domestic violence, stalking & human trafficking through community outreach, education, prevention, intervention, etc.'},
                ],
                confirmationDialog: false,
                amount: 0,
                amountWithdraw: 0,
                amountCharity: 0
            }
        },

        watch: {
            amount: function(val) {
                const vm = this
                const qty = parseInt(val)
                //- Asingar valor
                vm.amountWithdraw = qty*0.80
                vm.amountCharity = qty*0.20
            },
        },

        computed: {
            earnings () {
                return this.$store.getters['withdraw/getEarnings']
            },
            withdraws () {
                return this.$store.getters['withdraw/getWithdraw']
            },
            banking () {
                return this.$store.getters['withdraw/getBanking']
            },
        },

        mounted () {
            const vm = this
            // Find withdraw pending
            if (vm.withdraws) {
                vm.pending = vm.withdraws.find(element => element.status === 'pending')
            }
        },

        methods: {
            cancel () {
                this.$emit('close')
            },
            solicitedTransfer () {
                const vm = this
                vm.loading = true
                vm.confirmationDialog = false
                const charitySelected = vm.charitys.find(charity => charity.description === vm.charity)
                let withdraw = {
                    type: vm.type,
                    charity: vm.amountCharity,
                    withdraw: vm.amountWithdraw,
                    charityName: charitySelected.title
                }
                vm.$store.dispatch('withdraw/createWithdraw', withdraw).then(() => {
                    vm.loading = false
                    vm.$emit('close')
                    vm.$swal({
                        title: 'Withdrawal request submitted!',
                        text: 'You will receive a notification once the withdraw is approved',
                        icon: 'success',
                    })
                })
            },
            updateAmount (label) {
                switch (label) {
                    case 'withdraw':
                        this.amountCharity = this.amount - this.amountWithdraw
                    break
                    case 'charity':
                        this.amountWithdraw = this.amount - this.amountCharity
                    break
                }
            },
        },

        beforeDestroy () {
            const vm = this
            vm.pending = null
        }

    }
</script>

<style lang = 'scss'  scoped>

    $break-large: 1200px;
    $primary-color: #c29f52;
    .question{
        font-size: 1.3rem;
        color: rgb(132,132,132);
    }
    .select .v-input__control{
        width: 100% !important;
    }
    .menuable__content__active{
        top: 60px !important;
        left: 0 !important;
    }
    label.v-label{
        color: #000 !important;
    }
    .v-input__slot:before{
        background: $primary-color !important;
    }
    .user-login__field{
        width: 250px;
    }
    .user-login__field input[type="text"]{
        text-align: center !important;
    }

    .profile{
        .profile__title{
            font-size: 2rem;
            font-weight: 900;

            color: rgba(#000,0.7);
        }
        .profile__avatar{

            border-radius: 50%;

        }
        .avatar-container{
            border-radius: 50%;
            border: 1px solid rgba(red,0.4);
            overflow: hidden;
            padding: 5px;
        }
    }

</style>
